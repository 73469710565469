<template>
  <div class="home-coin-wrap" :class="{ show: coinShow }">
    <div class="home-coin" :class="{ show: coinShow, hide: !coinShow, fold: coinListFold }">
      <div class="coin-main">
        <h2 class="title">{{ $t('new_index.table.market_trend') }}</h2>
      </div>
      <div class="coin-tab">
        <div>
          <h3 :class="{ active: indexMarketTab === 0 }" @click="getBitExchange(0 ,true);">
            {{ $t('new_index.table.hot_futures_list') }}
          </h3>
          <div class="switch" :class="{ active: indexMarketTab === 0 }"></div>
        </div>
        <div style="width:32px;"></div>
        <div>
          <h3 :class="{ active: indexMarketTab === 1 }" @click="getBitExchange(1);">
            {{ $t('new_index.table.increase_list') }}
          </h3>
          <div class="switch" :class="{ active: indexMarketTab === 1 }"></div>
        </div>
        <div style="width:32px;"></div>
        <div>
          <h3 :class="{ active: indexMarketTab === 2 }" @click="getBitExchange(2);">
            {{ $t('new_index.table.trading_list') }}
          </h3>
          <div class="switch" :class="{ active: indexMarketTab === 2 }"></div>
        </div>
      </div>
      <div class="coin-split"></div>
      <div class="coin-list-title">
        <span>{{ $t('new_index.table.name') }}</span>
        <span>{{ $t('new_index.table.price') }}</span>
        <span>{{ $t('new_index.table.rise_fall_24h') }}</span>
        <span>{{ $t('index.bit_volume') }}</span>
        <span>{{ $t('new_index.table.quotation') }}</span>
        <span>{{ $t('new_index.table.opt') }}</span>
      </div>
      <!-- 全部合约 -->
      <div v-if="contractShow" v-for="(item, index) in contractMarketList">
        <div @click="swapEvt(item)" class="coin-list"
          :key="'contract_' + index">
          <span>
            <span>
              <img class="coin-list-icon" :src="item.iconUrl" alt="" />
            </span>
            <nuxt-link class="a-link" :to="goSwap(item)">
            {{ item.left }}
            <em>/{{ item.right }}</em>
            </nuxt-link>
            <span class="contract-icon__new" v-if="item.newP">
              <img src="~assets/img/contract/contract-icon__new.png" alt />
            </span>
          </span>
          <span>
            <!-- <i class="coin-list-pre">{{ getShowCode(item.pricedSymbol) }}</i> -->
            {{ item.price }}<br />
            <em> {{ currencySymbol.trim() }}{{ toCNYByUSDT(Number(item.price)) | fixToCNY }} </em>
          </span>
          <span>
            <em class="coin-list-float" :class="{ rise: item.float > 0, fall: item.float < 0 }">
              {{ item.floatText }}
            </em>
          </span>
          <span>
            {{ Number(item.trade) | formatMoney(false, ',', 0) }} USDT
          </span>
          <span class="clearfix">
            <span class="coin-chart" :ref="`chartContainer_${index + 1}`">
            </span>
          </span>
          <span>
            <div class="btn-transaction">
              <span>{{ $t('new_index.table.opt_btn') }}</span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { ACTION_TYPES } from "~/store/newTrading/types";
import { Decimal } from "decimal.js";
import { fixedDisplay } from "~/utils/utils.js";
import { mapGetters, mapState, mapActions } from 'vuex';
import { mixLink } from 'utils';
import { PC_TradePairClick, ClickHomeRankList, ClickHomeFuturePair } from "../../utils/sensorsEvent";
import { RESOLUTION_TO_NEW_CONTRACT } from '~/src/coin_coin_trade/ChartConfig';
import { subscribeTickerAll, unSubscribeTickerAll } from '~/src/newWebsocket2/tickerAll';
import highStock from 'highcharts/highstock';
import tradingApi from '~/server/newTradingApi';
import { getContractActiveRank } from "~/server/common";
// import { init } from "../swap/src/klinecharts/index";


export default {
  data() {
    return {
      coinListFold: true, // 是否折叠
      coinMixChartList: [],
      coinMixMarketList: [], // 混合合约
      coinMixMarketTabName: '',
      coinShow: false, // 为了动画效果
      contractChartList: [],
      contractMarketList: [], // 合约
      contractMarketTabName: '',
      displayQuoteList: [], // 实际展示用的行情数据
      indexMarketTab: 0, // 新的首页市场tab：0-熱門合約，1-涨幅榜，2-交易金额榜
      klineHistoryMap: new Map(),
      newContractSocketPublic: null,
      spotChartList: [],
      spotMarketList: [], // 币币
      spotMarketTabName: '',
      timerQuote: null,
      timerRenewHistory: null,
      usdtMixChartList: [],
      usdtMixMarketList: [], // USDT专业合约
      usdtMixMarketTabName: '',
      userSelectedChartList: [],
      topFutureIdList: [], // 熱門合約
      top5List: [], // 前五名熱門榜
      timerRenewtopFuture: null,
      fetchHistoryTimeout: null,
      initKlineByMarketTab: [false, false, false] // 判斷不同indexMarketTab是否已取得第一次klineData
    }
  },
  computed: {
    ...mapState({
      contractList: state => state.newTrading.contractList,
      newAllTicker: state => state.newTrading.newAllTicker,
    }),
    ...mapGetters(['currencySymbol', 'toCNYByUSDT']),
    contractShow() {
      return this.contractMarketList?.length > 0;
    },
    showShowMore() {
      return this.contractMarketList.length > 5 && this.coinListFold;
    },
    tickers() {
      const tickerData = this.newAllTicker || window.$nuxt.$store.state.newTrading.newAllTicker;
      let tickers = [];
      // 排除模擬盤
      const contracts = this.contractList?.filter(item => +item.quoteCoinId === 2 && item.enableDisplay === true);
      if (contracts?.length > 0 && tickerData) {
        contracts.forEach(contract => {
          const ticker = tickerData[contract.contractId];
          if (ticker) {
            ticker.productName = contract.productName;
            tickers.push(ticker);
          }
        });
      }
      return tickers;
    },
    quoteList() {
      try {
        // 获取行情数据
        let list = this.tickers;
        // 要移到最前
        const coinsToMoveToFront = this.top5List;
        let frontList = [];
        // 将要移到最前面的币种逐一处理
        coinsToMoveToFront.forEach(coin => {
          if (list.length > 0) {
            // 查找币种在列表中的索引
            const index = list.findIndex(item => item?.productName?.split('/')[0] === coin);
            if (index > -1) {
              const item = list.splice(index, 1)[0];
              frontList.push(item);
            }
          }
        });
        // 将币种移动到列表最前面
        if (frontList.length > 0) {
          list = frontList.concat(list);
        }
        // 返回处理后的列表
        return list;
      } catch (error) {
        console.error('Error fetching quotes:', error);
        return []; // 如果出现错误，返回一个空数组
      }
    },
    // 前向入口来源 (热门合约榜, 涨幅榜, 交易金额榜)
    forwardEntrySource () {
      switch (this.indexMarketTab) {
        case 0:
          return '热门合约榜';
        case 1:
          return '涨幅榜';
        case 2:
          return '交易金额榜';
        default:
          return '热门合约榜';
      }
    }
  },
  watch: {
    coinListFold() {
      this.$forceUpdate()
    },
    indexMarketTab(newVal) {
      this.getBitExchange(newVal);
    },
    contractShow: {
      handler(val) {
        if (!val) {
          this.indexMarketTab = 0;
        }
      },
      immediate: true
    },
    newAllTicker: {
      handler(newValue) {
        if (newValue) {
          this.getBitExchange(this.indexMarketTab);
        }
      },
      immediate: true
    },
    contractList(newValue) {
      // 防止contractList取得比topFutureIdList晚
      this.top5List = this.getTop5List(this.topFutureIdList);
      this.getBitExchange(this.indexMarketTab);
    },
    top5List(newValue) {
      // 防止top5List一開始為空的處理
      this.getBitExchange(this.indexMarketTab);
    },
    displayQuoteList(newValue) {
      if(newValue.length !== 0) {
        this.initKlineData();
      }
    }
  },
  filters: {
    fixToCNY(val) {
      if (Number(val) >= 1) {
        return Number(val).toFixed(2);
      } else {
        return Number(val).toFixed(6);
      }
    }
  },
  methods: {
    ...mapActions('newTrading', { fetchMetadata: ACTION_TYPES.FETCH_CONTRACT_LIST }),
    handlePageLoad() {
      // 获取行情数据
      this.fetchMetadata().then(() => {
        subscribeTickerAll();
      });
    },
    // 获取K线数据
    async getHistoryData(contractId) {
      let historyParam = {
        contractId: contractId,
        productCode: this.contractList?.find(item => item.contractId === +contractId)?.productCode || '',
        priceType: 'LAST_PRICE',
        klineType: RESOLUTION_TO_NEW_CONTRACT['1'],
        limit: 50
      };
      // 取得歷史数据
      return await tradingApi
        .getKlineHistory(historyParam)
        .then(data => {
          return (data.code === '00000') ? data.data || [] : [];
        }).catch((e) => {
          return [];
        });
    },
    getBitExchange(tabIndex ,clicked=false) {
      if(this.top5List.length <= 0) return; // 獲得熱門榜才往下處理
      const list = _.cloneDeep(this.quoteList);
      switch (tabIndex) {
        case 0:
          // 点击首页热门币榜单
          if (clicked) {
            ClickHomeRankList();
          }
          break;
        case 1:
          list.sort((a, b) => b.priceChangePercent - a.priceChangePercent);
          break;
        case 2:
          list.sort((a, b) => b.value - a.value);
          break;
      }
      if (list.length > 0) {
        const displayQuoteList = list?.splice(0, 5);
        this.displayQuoteList = displayQuoteList;
        this.fetchHistory(false, displayQuoteList).then(() => {
          this.displayMarket(displayQuoteList); // 显示行情
        });
      }
      if (!isNaN(tabIndex) && (this.indexMarketTab !== tabIndex)) {
        this.indexMarketTab = tabIndex;
      }
    },
    getCoinIcon(contractId) {
      return contractId ? this.contractList?.find(item => item.contractId === +contractId)?.iconUrl || '' : '';
    },
    /**
     * 点击查看更多
     */
    showMore() {
      this.coinListFold = false;
      this.getBitExchange();
    },
    displayMarket(list) {
      if (list && list.length > 0) {
        const finalList = list.map((item, index) => {
          const { productName, contractId, markPrice, priceChangePercent, value } = item ?? {};
          const product = productName?.split('/') || [];
          const coinSymbol = product[0]?.toUpperCase();
          const priceSymbol = product[1]?.toUpperCase();
          const tmpItem = {
            // 合约数据
            float: fixedDisplay((Number(priceChangePercent || 0) * 100), 2, Decimal.ROUND_FLOOR),
            floatText: fixedDisplay((Number(priceChangePercent || 0) * 100), 2, Decimal.ROUND_FLOOR, '%'),
            iconUrl: this.getCoinIcon(contractId),
            kline: this.klineHistoryMap.get(contractId) || [],
            left: coinSymbol,
            price: markPrice,
            productCode: `cmt_${productName?.replace('/', '')?.toLowerCase()}`,
            right: priceSymbol,
            trade: value,
            productName: productName
          }
          return tmpItem;
        });
        this.drawCharts(finalList);
        this.contractMarketList = finalList; // 僅取前五筆
        this.contractMarketTabName = this.$t('index.contract_list_pair');
        this.coinShow = true;
      }
    },
    async fetchHistory(renewData, displayQuoteList) {
      let list = _.cloneDeep(displayQuoteList);
      const historyList = list.map(item => item.contractId);
      if (historyList.length > 0) {
        // 使用 Promise.all 并行获取所有历史数据
        const histories = await Promise.all(
          list.map(item => {
            // 如果需要更新数据，则重新获取，否则直接返回历史数据
            //return (renewData || !this.klineHistoryMap.get(item.contractId))
            return renewData
              ? this.getHistoryData(item.contractId)
              : this.klineHistoryMap.get(item.contractId); // 若是有历史数据且非数据更新请求，则直接返回历史数据
          })
        );
        list.forEach(item => {
          const history = histories.find(h => h?.nextKey?.contractId === item.contractId);
          if (history) {
            this.klineHistoryMap.set(item.contractId, history.dataList || []);
          }
        });
      }
    },
    drawCharts(list) {
      if (list?.length === 0) return;
      // 因为是异步获取数据，所以需要判断是否已经有了chart，更新chart也是异步，所以在最后一次性更新
      let chartList = this.contractChartList;
      // 画趋势图
      list.forEach((item, index) => {
        if (item?.kline.length > 0) {
          let klineChart = [];
          item.kline.forEach(subItem => {
            klineChart.push(Number(subItem.close));
          });
          const min = Math.min.apply(null, klineChart);
          const max = Math.max.apply(null, klineChart);
          try {
            const contractChartItem = chartList[index];
            if (contractChartItem && contractChartItem?.index) {
              try {
                contractChartItem.update(this.setHighChartOption(item.float, klineChart, min, max));
              } catch (err) {
                console.log(err);
              }
            } else {
              const lineChart = this.$refs[`chartContainer_${index + 1}`];
              if (lineChart) {
                const chart = new highStock.chart(lineChart[0],
                  this.setHighChartOption(item.float, klineChart, min, max)
                );
                chartList.push(chart);
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
      });
      this.contractChartList = chartList;
    },
    // hightChar 设置项
    setHighChartOption(isFall, data, min, max) {
      isFall = Number(isFall);
      let cBorder = '#28C452',
        cBegin = 'rgba(40,196,82,0.1)',
        cEnd = 'rgba(40,196,82,0)';
      if (isFall < 0) {
        cBorder = '#EC4551';
        cBegin = 'rgba(236,69,81,0.1)';
        cEnd = 'rgba(236,69,81,0)';
      }
      return {
        legend: {
          enabled: false
        },
        chart: {
          type: 'area',
          marginBottom: 0,
          marginTop: 0,
          height: 36,
          width: 125,
          marginLeft: -10,
          marginRight: -10,
          backgroundColor: 'rgba(0,0,0,0)'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          visible: false,
          max: data.length - 1
        },
        yAxis: {
          visible: false,
          max,
          min
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          area: {
            fillOpacity: 0.3, // 指定所有面积图的透明度
            enableMouseTracking: false
          },
          marker: {
            enabled: false
          },
          series: {
            marker: {
              enabled: false
            }
          }
        },
        series: [
          {
            dataLabels: {
              enabled: false
            },
            color: cBorder,
            lineWidth: 1.5,
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, cBegin],
                [1, highStock.Color(cEnd).get('rgba')]
              ]
            },
            data: data
          }
        ]
      };
    },

    addOrRemoveUserSelected(item) {
      if (this.userSelectedList.find(o => o.symbolId === item.symbolId)) {
        this.removeUserSelected(item);
      } else {
        this.addUserSelected(item);
      }
    },

    // 移除自选
    removeUserSelected(item) {
      let { businessLine, symbolId } = item;
      if (businessLine && symbolId) {
        deleteUserSelf({ businessLine, symbolId }).then(data => {
          if (data.code === '00000') {
            this.getBitExchange();
          }
        });
      }
    },
    // 追加用户自选
    addUserSelected(item) {
      let { businessLine, symbolId } = item;
      if (businessLine && symbolId) {
        addUserSelf({ businessLine, symbolId }).then(data => {
          if (data.code === '00000') {
            this.getBitExchange();
          }
        });
      }
    },
    // 用户自选的跳转
    goUserSelected(item, e) {
      if (e.target.className.indexOf !== undefined && e.target.className.indexOf('icon-xingxing') > -1) {
        this.addOrRemoveUserSelected(item);
        return;
      }
      if (item.symbolId) {
        // 混合合约和币币
        let [_, bl] = item.symbolId.split('_');
        switch (bl) {
          case 'SPBL':
            // 币币
            this.goSpot(item.left.toLowerCase() + '_' + item.right.toLowerCase(), item.status);
            break;
          case 'UMCBL':
            // 专业合约
            this.goMix(item.symbolId, item.status, 'usdt');
            break;
          case 'DMCBL':
            // 混合合约
            this.goMix(item.symbolId, item.status, 'usd');
            break;
        }
      } else if (item.productCode) {
        // 普通合约
        // this.goSwap(item);
      }
    },
    // 跳转到 合约交易页面
    goSwap(item,type = 1){
      return this.$i18n.path((type === 1 ? 'swapnew/' : 'delivery/') + item.productCode)
    },
    swapEvt(item,type = 1) {
      if (item.productCode) {
        PC_TradePairClick(item.productCode)
        // 点击首页合约交易对埋点
        ClickHomeFuturePair(item.productName, this.forwardEntrySource);
        this.$router.push({
          path: this.$i18n.path((type === 1 ? 'swapnew/' : 'delivery/') + item.productCode)
        });
      }
      return false;
    },
    goMix(code, status, type) {
      if (status === 2) return;
      window.location.href = mixLink('/mix/' + type + '/' + code);
    },
    goSpot(code, status) {
      if (status === 2) {
        return;
      }
      if (code) {
        this.$router.push({
          path: this.$i18n.path('trade/' + code)
        });
      }
      return false;
    },
    goContract() {
      this.$router.push({
        path: this.$i18n.path('swap/btcusd')
      });
    },
    goTrade() {
      this.$router.push({
        path: this.$i18n.path('trade')
      });
    },
    // 獲取熱門合約榜
    getContractRank() {
      getContractActiveRank().then(data => {
          if (data.code === '00000') {
           this.topFutureIdList = data.data.slice(0, 5);
           this.top5List = this.getTop5List(this.topFutureIdList);
          }
      }).catch(() => {
          //BT.util.error(this.$t('trade.operation.orderFail'))
      });
    },
    getTop5List(list) {
      const filteredList = [];
      list.forEach(contractId => { // 自选列表按添加自选时间倒序排列
        const findSelect = this.contractList.find(element => Number(element.contractId) === contractId);
        findSelect && filteredList.push(findSelect.baseSymbol);
      });
      return filteredList;
    },
    // 切換tab, 第一次獲取過klineData, 取過第一次後 切換tab不會立即重新獲取kline
    initKlineData() {
      // 依據initKlineByMarketTab 來判斷在不同tab下, 是否有第一次獲取過klineData
      if (this.initKlineByMarketTab[this.indexMarketTab]) {
        return;
      }
      this.initKlineByMarketTab[this.indexMarketTab] = true;
      this.fetchHistoryTimeout && clearTimeout(this.fetchHistoryTimeout);
      this.fetchHistoryTimeout = setTimeout(() => {
        this.fetchHistory(true, this.displayQuoteList);
      }, 500);
    }
    /*changeCurrentUnit(price) {
      const tempUnit = this.toCNYByUSDT(Number(price));
      let CurrentUnit = '';
      CurrentUnit = (Number(tempUnit) >= 1)
        ? Number(tempUnit).toFixed(2).toString()
        : Number(tempUnit).toFixed(6).toString();
      return `${this.currencySymbol.trim()}${CurrentUnit}`;
    }*/
  },
  mounted() {
    this.handlePageLoad();
    this.getContractRank(); // 首次進入首頁立即获取热门合约数据
    if(this.timerRenewtopFuture) clearInterval(this.timerRenewtopFuture);
    this.timerRenewtopFuture = setInterval(() => {
      // 每1hr重新获取热门合约数据
      this.initKlineByMarketTab[0] = false; //每小時會重新取熱門合約數據,要把 initKlineByMarketTab[0]改成false,才會重新取
      this.getContractRank();
    }, 3600000);
    if(this.timerQuote) clearInterval(this.timerQuote);
    this.timerQuote = setInterval(() => {
      if (this.contractMarketList.length > 0) {
        this.getBitExchange(this.indexMarketTab);
      }
    }, 1500);
    if(this.timerRenewHistory) clearInterval(this.timerRenewHistory);
    this.timerRenewHistory = setInterval(() => {
      // 每5min重新获取历史数据
      this.fetchHistory(true, this.displayQuoteList);
    }, 300000);
  },
  beforeDestroy() {
    // 取消订阅
    unSubscribeTickerAll();
    // 关闭ws
    // let ws = this.$publicWS.instance;
    // if (ws) {
    //   ws.close();
    //   ws = null;
    // }
    // 关闭计时器
    this.timerQuote && clearInterval(this.timerQuote);
    this.timerRenewHistory && clearInterval(this.timerRenewHistory);
    this.timerRenewtopFuture && clearInterval(this.timerRenewtopFuture);
    this.fetchHistoryTimeout && clearTimeout(this.fetchHistoryTimeout);
    this.contractChartList.forEach((e) => {
      // e?.destroy()
      e = null
    });
    this.contractChartList.length = 0
    this.spotChartList.forEach((e) => {
      e?.destroy()
      e = null
    });
    this.spotChartList.length = 0;
    this.userSelectedChartList.forEach(e => {
      e?.destroy()
      e = null
    });
    this.userSelectedChartList.length = 0;
  },
}
</script>
<style lang="less">
.home-coin-wrap {
  min-height: 579px;
  margin-top: 0px;

  &.show {
    min-height: auto;
  }

  .home-coin {
    width: 1200px;
    margin: 60px auto;
    padding: 0 20px 30px;
    background-color: #fff;
    border-radius: 4px;

    .coin-main {
      display: flex;
      justify-content: space-between;
    }

    &.fold {
      padding: 0 20px 0px;
    }

    &.show {
      visibility: inherit;
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.35s;
    }

    &.hide {
      visibility: hidden;
      opacity: 0;
      transform: translateY(25px);
      transition: all 0.35s;
    }

    .title {
      .bold-font();
      font-size: 40px;
      color: #000000;
      line-height: 56px;
    }

    .coin-tab {
      .flex();
      .flex-cross-center();
      padding: 0 0 24px;

      h3 {
        display: inline-block;
        height: 28px;
        padding: 80px 0 8px;
        font-size: 20px;
        line-height: 28px;
        color: @gray-P1;
        .medium-font();
        cursor: pointer;

        &:hover {
          color: @yellow-P2-new !important;
        }

        &.active {
          color: #fff;
          border-color: @yellow-P2-new;
          border-width: 4px;
        }
      }

      .switch {
        width: 16px;
        height: 4px;
        //background: @theme-white;
        margin: 0 auto;

        &.active {
          background: @yellow-P2-new;
        }
      }
    }

    .coin-split {
      width: 1140px;
      height: 1px;
      margin: 0 auto;
    }

    .list-span {
      >span {
        display: inline-block;
        box-sizing: border-box;

        &:nth-of-type(1) {
          width: 212px;

          .iconfont {
            color: #a7b1bb;

            &.selected {
              color: @yellow-P3;
            }
          }
        }

        &:nth-of-type(2) {
          width: 212px;
        }

        &:nth-of-type(3) {
          width: 237px;
        }

        &:nth-of-type(4) {
          width: 251px;
        }

        &:nth-of-type(5) {
          width: 212px;
        }

        &:nth-of-type(6) {
          width: 76px;
          text-align: left;
        }

      }
    }

    // 合约列表
    .coin-list-title {
      font-size: 14px;
      color: @gray-P1;
      .medium-font();
      height: 20px;
      line-height: 20px;
      margin: 0 0 8px;
      .list-span();
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .coin-show-more {
      text-align: center;
      line-height: 56px;
      font-size: 14px;
      color: @gray-P1;
      align-items: center;
      .flex();
      cursor: pointer;

      &:hover {
        color: @yellow-P3;

        .icon-more {
          background: url(~assets/img/new_index/yellow_right_Hover.png) center no-repeat;
          background-size: 16px 16px;
        }
      }

      >i {
        margin-left: 4px;
        font-size: 12px;
        transform: scale(0.9);
      }

      .icon-more {
        width: 14px;
        height: 14px;
        margin-left: 8px;
        background: url(~assets/img/new_index/icon_more.svg) center no-repeat;
      }
    }

    .coin-list {
      display: block;
      width: 1200px;
      padding: 0 20px;
      margin-left: -20px;
      .medium-font();
      height: 70px;
      font-size: 16px;
      color: #000000;
      .list-span();
      .flex();
      .flex-cross-center();
      justify-content: space-between;
      cursor: pointer;
      .a-link{
        color: #fff;
        pointer-events: none;
      }
      &:hover {
        background-color: #191b20;
        border-radius: 8px;
      }

      >span {
        font-size: 16px;

        &:nth-of-type(1) {
          width: 212px;
          line-height: 70px;

          em {
            color: @gray-P1;
            font-size: 14px;
            height: 18px;
            line-height: 18px;
            .medium-font();
          }

          .contract-icon__new {
            display: inline-block;
            width: 26px;
            height: 16px;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }

        &:nth-of-type(2) {
          width: 212px;

          em {
            display: inline-block;
            font-size: 14px;
            color: @gray-P1;
            .medium-font();
          }
        }

        &:nth-of-type(3) {
          width: 237px;
        }

        &:nth-of-type(4) {
          width: 251px;
        }

        &:nth-of-type(5) {
          width: 212px;
        }

        &:nth-of-type(6) {
          width: 70px;
        }

        .coin-list-float {
          display: inline-block;
          width: 160px;
          height: 28px;
          line-height: 28px;
          text-align: left;
          font-size: 16px;
          color: @gray-P1;
          border-radius: 2px;

          // 涨
          &.rise {
            color: @green-color;
          }

          // 跌
          &.fall {
            color: @red-color;
          }
        }

        .btn-transaction {
          width: 70px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: @black-btn-line;
          border-radius: 6px;
          box-sizing: border-box;
          .medium-font();
          color: #000000;
          font-size: 14px;

          >span {
            line-height: 32px;
            height: 32px;
          }
        }

        .coin-chart {
          width: 125px;
          height: 36px;
          overflow: hidden;
        }

        .coin-list-icon {
          width: 30px;
          height: 30px;
          display: inline-block;
          top: 10px;
          position: relative;
          margin-right: 16px;
        }

        .coin-list-pre {
          display: inline-block;
          margin-right: 2px;
          font-style: normal;
          font-weight: normal;
        }

        .coin-list-margin {
          display: inline-block;
          margin-right: 2px;
          font-style: normal;
        }
      }
    }
  }
}
</style>
